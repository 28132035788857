import React from 'react';
import {Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'


const ArticlesList = ({articles}) => (
    <React.Fragment>
        {articles.map((article, key) => (
            <Link className="App-link" key={key} to={`/article/${article.name}`}>
                <h3 className="alert alert-dark">{article.title}</h3>
                <p>{article.content[0].substring(0,150)}...</p>
            </Link>
        ))}
    </React.Fragment>
);

export default ArticlesList;