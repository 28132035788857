import React,{useState} from 'react';
import {Table, DropdownButton, Dropdown} from 'react-bootstrap';

function Toolbox (){
    const [Dimension, setDimension] = useState('Dimension');
    const [Unit, setUnit] = useState('Unit');
    const [unitOne, setUnitOne] = useState('');
    const [unitTwo, setUnitTwo] = useState('');
    const [unitThree, setUnitThree] = useState('');
    const [factor, setFactor] = useState(0);
    const [userInput, setUserInput] = useState(0);
    const [ansUnit, setAnsUnit] = useState('');
    const SetFactor = (Unit) => {
        if (Unit==='mm'){setFactor(1e-3);setAnsUnit('m')};
        if (Unit==='cm'){setFactor(1e-2);setAnsUnit('m')};
        if (Unit==='dm'){setFactor(1e-1);setAnsUnit('m')};
        if (Unit==='mm2'){setFactor(1e-6);setAnsUnit('m2')};
        if (Unit==='cm2'){setFactor(1e-4);setAnsUnit('m2')};
        if (Unit==='dm2'){setFactor(1e-2);setAnsUnit('m2')};
        if (Unit==='mm3'){setFactor(1e-9);setAnsUnit('m3')};
        if (Unit==='cm3'){setFactor(1e-6);setAnsUnit('m3')};
        if (Unit==='dm3'){setFactor(1e-3);setAnsUnit('m3')};
        if (Unit==='mm4'){setFactor(1e-12);setAnsUnit('m4')};
        if (Unit==='cm4'){setFactor(1e-8);setAnsUnit('m4')};
        if (Unit==='dm4'){setFactor(1e-4);setAnsUnit('m4')};
        if (Unit==='mm6'){setFactor(1e-18);setAnsUnit('m6')};
        if (Unit==='cm6'){setFactor(1e-12);setAnsUnit('m6')};
        if (Unit==='dm6'){setFactor(1e-6);setAnsUnit('m6')};
    }
    

    return(
        <div>
            <Table striped bordered hover variant="dark">
                <tr>
                    <th>
                        <DropdownButton variant='dark' title={Dimension} value={Dimension}>
                            <Dropdown.Item as="button" onClick={() => {setDimension('Dim');setUnit('Unit');setUnitOne('mm');setUnitTwo('cm');setUnitThree('dm');}}>Dim^1</Dropdown.Item>
                            <Dropdown.Item as="button" onClick={() => {setDimension('Dim^2');setUnit('Unit');setUnitOne('mm2');setUnitTwo('cm2');setUnitThree('dm2');}}>Dim^2</Dropdown.Item>
                            <Dropdown.Item as="button" onClick={() => {setDimension('Dim^3');setUnit('Unit');setUnitOne('mm3');setUnitTwo('cm3');setUnitThree('dm3');}}>Dim^3</Dropdown.Item>
                            <Dropdown.Item as="button" onClick={() => {setDimension('Dim^4');setUnit('Unit');setUnitOne('mm4');setUnitTwo('cm4');setUnitThree('dm4');}}>Dim^4</Dropdown.Item>
                            <Dropdown.Item as="button" onClick={() => {setDimension('Dim^6');setUnit('Unit');setUnitOne('mm6');setUnitTwo('cm6');setUnitThree('dm6');}}>Dim^6</Dropdown.Item>
                        </DropdownButton>
                    </th>
                    <th>
                        <DropdownButton variant='dark' title={Unit} value={Unit}>
                            <Dropdown.Item as="button" onClick={() => {setUnit(unitOne)}}>{unitOne}</Dropdown.Item>
                            <Dropdown.Item as="button" onClick={() => {setUnit(unitTwo)}}>{unitTwo}</Dropdown.Item>
                            <Dropdown.Item as="button" onClick={() => {setUnit(unitThree)}}>{unitThree}</Dropdown.Item>
                        </DropdownButton>
                    </th>
                    <th colSpan='2'>
                        <input onChange={(event) => {setUserInput(event.target.value);SetFactor(Unit);}}/> {Unit} = <input value={factor*userInput}/> {ansUnit}
                    </th>
                </tr>
                <tr>
                    <th>Material</th>
                    <th>Concrete</th>
                    <th colSpan='2'>selfweight=25kN/m3</th>
                </tr>
                <tr>
                    <th></th>
                    <th>Steel</th>
                    <th colSpan='2'>selfweight=78.5kN/m3</th>
                </tr>
                <tr>
                    <th></th>
                    <th>Brickwork</th>
                    <th colSpan='2'>selfweight=19kN/m3</th>
                </tr>
                <tr>
                    <th>Floor Load</th>
                    <th>Typical Office Floor</th>
                    <th>130mm Kingspan 60-v2<br/>200mm Raised Access<br/>Ceiling and Services<br/>Steelwork</th>
                    <th>2.3kN/m2<br/>0.5kN/m2<br/>0.5kN/m2<br/>0.75kN/m2</th>
                </tr>
                <tr>
                    <th></th>
                    <th>Office Floor Supporting Plant</th>
                    <th>130mm Kingspan 60-v2<br/>175mm Roof Insulation<br/>Single Ply Roof Membrane<br/>Ceiling and Services<br/>Steelwork</th>
                    <th>2.3kN/m2<br/>0.1kN/m2<br/>0.1kN/m2<br/>0.5kN/m2<br/>0.75kN/m2</th>
                </tr>
            </Table>
        </div>
        
    );
}

export default Toolbox;