import { Button, Collapse, Form } from 'react-bootstrap';
import React,{useState} from 'react';
import {InputGroup} from 'react-bootstrap';
import {Container} from 'react-bootstrap';
import {Option} from 'react-bootstrap';

function IBeamTorsion() {
    const [openConfiguration, setOpenConfiguration] = useState(false);
    const [openSection, setOpenSection] = useState(false);

    const [loadUnit, setLoadUnit] = useState("kN/m");
    const [loadValue, setLoadValue] = useState(0);

    return(
    <div>
        <h1 classname="App-header">I Beam Torsion Analysis (In development)</h1>
        <p>This analysis uses the SCI P385 assessment method, allowing for elastic interacting between St Venant torsion and warping torsion when calculating warping moment.</p>
        <Button classname="float-sm-left" onClick={() => setOpenConfiguration(!openConfiguration)} aria-controls="example-collapse-text" aria-expanded={openConfiguration}>
            Configuration
        </Button>
        <Collapse in={openConfiguration}>
            <Container>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroup-sizing-default">End condition</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control as="select">
                        <option>simply supported</option>
                    </Form.Control>
                </InputGroup>

                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text>Beam span</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control aria-label="Amount (to the nearest dollar)" />
                    <InputGroup.Append>
                        <InputGroup.Text>m</InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>

                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text>Loading</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control as="select" onChange={(event)=>{setLoadUnit(event.target.value)}}>
                        <option value="kN/m">Uniformly distributed</option>
                        <option value="kN">Point load at mid span</option>
                    </Form.Control>
                    <Form.Control onChange={(event)=>{setLoadValue(event.target.value)}}/>
                    <InputGroup.Append>
                        <InputGroup.Text>{loadUnit}</InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>

                
            </Container>
        </Collapse>
        <br />
        <br />
        <Button classname="float-sm-left" onClick={() => setOpenSection(!openSection)} aria-controls="example-collapse-text" aria-expanded={openSection}>
            Section properties
        </Button>
        <Collapse in={openSection}>
            <Container>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroup-sizing-default">End condition</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control as="select">
                        <option>simply supported</option>
                    </Form.Control>
                </InputGroup>

                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text>Beam span</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control aria-label="Amount (to the nearest dollar)" />
                    <InputGroup.Append>
                        <InputGroup.Text>m</InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>

                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text>Loading</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control as="select" onChange={(event)=>{setLoadUnit(event.target.value)}}>
                        <option value="kN/m">Uniformly distributed</option>
                        <option value="kN">Point load at mid span</option>
                    </Form.Control>
                    <Form.Control onChange={(event)=>{setLoadValue(event.target.value)}}/>
                    <InputGroup.Append>
                        <InputGroup.Text>{loadUnit}</InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>

                
            </Container>
        </Collapse>
    </div>
    )
};

export default IBeamTorsion;