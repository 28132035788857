import React from 'react';

const articleContent = [
    {
        name: 'ChoosingSteelTypes',
        title: 'Choosing Steel Types',
        content: [
            "Different types of steel manufacturing can perform better for specific applications. Construction industry occupies more than 50% of the world steel demand.",
            "Brief History of Steel: Iron as a construction material started in the fifteenth century Britain, manufacturing large quantities of iron. This has been developed into the modern steelmaking process with the invention of the air blown converter in 1856.",
            "Hot Rolled Steel: Hot rolling refers to the mill process which you roll the steel at a temperature above its recrystallisation temperature, typically above five hundred degrees. Due to the high temperature, it becomes more malleable and can be easily shaped and can be produced in large quantity. It is then cooled in room temperature, which normalises it, to avoid the worry for stresses in the material arising when quenching or work-hardening. When the steel cools off, it will shrink non-uniformly, which gives slightly less control on the overall size and shape of a finished hot-rolled product. Its typically has a scaly surface finish. When the appearance of the material is a concern, several techniques are available, such as pickling, grinding, or sand-blasting.",
            "Cold Rolled Steel: Cold rolling refers to steel that is pressed with the pressure of a roller at room temperature. Its has nearly 20% increase in strength comparing to hot rolled steel, but lower on ductility and toughness through strain hardening. It is through a lengthy process that steel shapes can be created. Cold-rolling steel allows for the creation of very precise shapes. Since the process is done in room temperature, it does not shrink as it cool. The surface is aesthetically pleasing. However, the applications of cold-rolled steel are limited to a couple of shapes - square, round, flat, and others. Cold rolled steel cannot be designed from methods given by BS 5950:Part 5.",
            "Cast steel: Casting is the method of pouring liquid steel into a mold to produce solid metal objects. Typically used for complex or non-standard structural components.",
        ]
    },
    {
        name: 'SteelBeamDesign',
        title: 'Rules for Steel Beam Design',
        content: [
            "Steel beams are prone to buckle along its length. In the case of a simply supported beam, this is prevented by restaining its compression flange, which prevents it from rotating along its axis. This is known as lateral-torsional buckling. First, we should look at the restrain, which dictates the effective length of the beam. This in turns governs the size of the member. The general rule to classify a restraint that prevents the compressive flange from rotating is to resist a force equivalent to 2.5% of the ultimate compression load in the top flange of the beam it is restaining. For intermediate restraint, simply tying to an adjacent beam is not stiff enough, laterally load must direct into stiff elements such as bracing and walls must exist.",
            "If the beam does not have compressive flange restraint, end condition must be established. Beam end can be laterally, torsionally and rotationally restrained. EC3 establishes a reduction factor that is applied to the bending moment resistance of the beam.",
            "Steel material properties: Density of steel is 7850kg/m3 and the Young Modulus (E) is 210,000N/mm2. Steel with a nominal yield strength of 275N/mm2 and 355N/mm2 are typically used. Element yield strength reduces as section max thickness increases.",
            "Classification of beam sections: Clause 5.5.2 in BS EN 1993-1-1 groups steel beams into four classifications. These classifications are based on a steel beam section’s resistance to suffering from a local failure due to buckling. It is a subject of the section web and flange thickness.",
            "Shear capacity: The majority of the shear force is applied to its web. If shear is failing, stiffeners can be installed to support the high shear load. Steel shear strength is 1/sq(3) yield strength.",
            "Bending moment capacity: Governed by section modulus and yield strength. Bending moment resistance is reduced if the applied force is more than half of the plastic shear resistance of the beam. When this happens, Clause 6.2.8 of BS EN 1993-1-1 applies. This places a modification factor against the yield strength. This only applies regionally to the part of the beam where high shear occurs. Class 4 section will need further guidance from BE EN 1993-1-5.",
            "Lateral torsional buckling: In the case of an unrestrained portion of a beam, a factor is applied to the bending moment resistance to account for the risk of LTB.This reduction is related to the slenderness of the beam. This relates to the distance between restraints at the compressive flange of the element. This can be calculated using the red book method. However, for destabilising load, a more detailed method must be used.",
            "Temperature load: For beams that are subject to temperature difference, the element is prone to expand and shrink. When deflection is restrained by stiff elements, large axial forces will result from temperature load.",
            "Deflection: For long-span beam, serviceability becomes an issue. The vertical deflection limits for steel beams can be found in the clause NA.2.23 of the UK NA to BS EN 1993-1-1. Deflection for total load and live load are the two typically checked item. Global deflection should also be considered, which is very often missed.",
        ]
    },
    {
        name: 'SteelBeamInTorsion',
        title: 'Steel Beam in Torsion',
        content: [
            "Elastic theory of torsion is the fundamental base on steel beam design in torsion. In this theory, the material behaves elastically, with pure torsion as the fundamental form.",
            "Pure torsion, also known as St Venant torsion, occurs when a uniform bar subject to equal and opposite torques at each free to wrap end to resist the torque at each cross-section area. The total shear stress over the cross-section is equal to the torsional moment in the beam and the beam will twist about a longitudinal axis known as its shear centre. A close section is much more effective in pure torsion because of the shear stress direction along the cross-section is almost uniform, different from an open section which has opposite stress variation. Wrapping is generated due to non-uniform stress along the cross-section. Hence, except perfectly circular elements, wrapping will be experienced. For solid, hollow section, angle and Tee sections, wrapping is negligible. It is much more significant in double flange open section.",
            "Warping torsion occurs when warping of the cross-sections is constrained, longitudinal stresses and additional shear stresses are developed and the torsion is partly resisted by those additional shear stresses. At any point in the span, the torsion is carried partly as St Venant torsion and party warping torsion (by the shear stresses caused by the restraint of warping). To determine the effect of the two types of torsion, a deflection shape is formulated that reflects the various stiffnesses.",
            "Warping stresses can occur from either internal restraint associated with a non-uniform moment or to external restraint at the ends. Longitudinal warping stresses are greatest at the tip of the flange and the warping shear stress is greatest at the junction with the web. For I section, warping shear stress is small enough to be ignored.",
            "A conservative assessment of warping effects in a flanged beam would be to ignore the St Vanent torsional stiffness and apply the torque as a couple of forces between the top and bottom flanges. For a long beam, this can be very conservative.",
            "Torsional bending constant which has the dimensions of length is an indicator of how quickly the effect of the warping restraint dissipates. The larger the value means a larger warping moment. It is observed that within sections with a similar depth, heavier section rely more on pure torsion, likely due to the increase in cross-section area which leads to larger utility in shear stress.",
            "Temporary stability should be accounted for when looking at the construction stage. The first thing to look at is the floor type. For in-situ composite slab, the beam is either restrained by fixed decking spanning transverse to the beam with in-plane stiffness, or by secondary beams that support longitudinal decking at 3 to 4m centres. When precast is used, provided that are of equal span to either side of the beam they may be assumed to provide restraint through a combination of restoring moment and friction for beam spans to 500/3 times the unit’s bearing width. This means spans up to 8m may typically be assured to be restrained.",
        ]
    },
    {
        name: 'SteelColumnDesign',
        title: 'Rules for Steel column Design',
        content: [
            "Steel material properties: Density of steel is 7850kg/m3 and the Young Modulus (E) is 210,000N/mm2. Steel with a nominal yield strength of 275N/mm2 and 355N/mm2 are typically used. Element yield strength reduces as section max thickness increases.",
            "Buckling is the failure mode for a column. First, evaluate the axial and bending applied to the column. Bending moment is taken as the axial load applied 100mm from the outer face of the flange. This is then distributed above and below the floor. This is based on the section stiffness between the two floors calculated as EI/L. If the ratio is less then 1.5 they are evenly distributed. The resistance of the column is based on the likelihood of buckling, as that is the mode of failure for columns. Clause 6.3.1.3 in BS EN 1993-1-1 explains this is calculated as non-dimensional slenderness of the columns. Typically, minor axis will produce the worst reduction factor. For rolled steel sections this is done by using eq 6.50. Reduction factor can be read through a graph, which is applied to the buckling resistance.",
            "Bending moment resistance of steel columns is the same as that derived for a beam section. Reduction factor from lateral torsional buckling is considered in the resistance.",
            "Slenderness is the lateral-torsional buckling factor. This can be conservatively assumed to be 0.9 buckling factor ",
        ]
    },
    {
        name: 'SteelColumnBase',
        title: 'Steel Column Bases',
        content: [
            "Column bases are usually assumed to be subject to axial compression and shear only. Uplift should be considered for column bases in braced bays.",
            "Column bases typically consist of a square or rectangular plate welded to the column, with four holding down bolts provided outside the section profile. The first reason is the wide spacing of the bolts increases stability in the temporary condition. The second reason is that it makes plumbing (vertical) the column simpler. The last reason is the wider spacing of bolt location can ensure the holding down bolts have enough concrete to interact with.",
            "The base plate should be a sufficient size, stiffness and strength to transmit the axial compressive force from the column to the foundation through the bedding material, without exceeding the local bearing capacity of the foundation.",
            "Holding down system is intended to meet the following requirements:",
            <ul>
                <li>During erection, they must be capable of stabilising the column until other structural elements have been erected</li>
                <li>During construction, they must resist uplift</li>
                <li>In service, they must transmit shear from the column to the foundation</li>
                <li>In service, they must resist uplift in braced bays</li>
            </ul>,
            " Holding down bolts are usually placed in conical sleeves. An anchor plate is generally provided for each bolt, although a common plate. Holding down bolts should be moved as the concrete cures, to allow lateral movement when the steel is located. If not, bolts will be held so rigidly that they cannot be inclined to adjust the alignment of the steel base. Bolt holes through the base plate are made 6mm oversize to allow holding down bolts to be inclined. The best practice is to have all holding down bolts of the same grade because once the concrete has been cast, errors can be expensive to rectify.",
        ]
    },
    {
        name: 'HealthAndSafetyForStructuralEgineer',
        title: 'Health and Safety For Structural Engineer',
        content: [
            "Health and safety offences are concerned with creating a risk of harm. They do not require proving that the offence has caused any actual harm. The standard of Health and safety in the UK is enforced by the government agency Health and Safety Executive. Short-form HSE. This includes work in all occupations.",
            "For Structural engineers, responsibilities are as follow:",
            "As an organisation:",
            <ul>
                <li>Health and Safety at Work Act section3 ' Risk to non-employees' to not exposing non-employees to harm as reasonably practicable.</li>
                <li>Corporate Manslaughter Act when things go wrong and senior management has taken action to cause the problem.</li>
                <li>CDM 2015 (Reg 9)</li>
                <li>Management of Health and Safety at Work Act relates to the requirement for risk assessments.</li>
                <li>Duty of care to others under common law</li>
                <li>Contractual</li>
            </ul>,
            "As an employee:",
            <ul>
                <li>Health and Safety at Work Act  section 7 Take reasonable care for H and S of himself and others and section37 'Director's duties'</li>
                <li>Gross negligence manslaughter which is compatible as other manslaughter charges</li>
                <li>CDM 2015 (Reg 9)</li>
                <li>Duty of care to others under common law</li>
                <li>Duty to employer</li>
            </ul>,
            "Under CDM2015, the principal designer is a role responsible to produce efficient designs that can be built, used and maintained healthily and safely by contractor who are provided with sufficient information on reduced significant residual risks within reasonable bounds. Principle contractor is in response in a similar manner in the construction phase.",
            "To discharge the rile of principal designer, a set of appropriate documents are usually created.",
            <ul>
                <li>Client brief is written at the start of the project. Depends on the construction knowledge of the client, assistance form the principal designer is required.</li>
                <li>Resource schedule and the associated fee is produced to show the resources required to undertake the role of principal designer against the project programme in order to determine a fee for the role.</li>
                <li>Pre-construction information is a document initially containing information in client’s procession relevant to the project, with an appropriate level of detail and proportionate to the project risk involved. It is then added and recirculated with the team of designers to be subsequently and continuously used by the designers, who should work under the health and safety control of the principal designer.</li>
                <li>The principle designer should:
                        <ul>
                            <li>ensure that immediate information from the client is circulated to designers at the start of the project</li>
                            <li>ensure that information gaps are identified by designers and the principal designer (continuous process)</li>
                            <li>managing the distribution of further information as it becomes available (continuous process)</li>
                            <li>distribute information supplied by designers on health and safety hazards from the design and construction process and how they will be addressed (continuous process)</li>
                            <li>distribute information to the principal contractor to allow incorporation into the Construction Phase Plan and management of significant residual health and safety hazards (continuous process).</li>
                        </ul>
                    </li>
                <li>Design ‘significant residual risk’ schedules</li>
                <li>Health and Safety File</li>
            </ul>,
        ]
    },
    {
        name: 'FireProtectInSteel',
        title: 'Fire Protection in Steel',
        content: [
            "Designers must follow Approved Document B advise to comply with building regulation. BS 9999, Code of practice for fire safety in the design, management and use of buildings which is known to provide safer solutions for fire than the approved document B due to extensive research.",
            "Fire resistance requirement is stated in terms of minutes. It is not how long the structure will withstand the fire, but a standard measure for comparing the performance of different designs in a consistent manner. The requirement of different floors depended on the existence of sprinklers, building occupation, height and floor location.",
            "All structural hot rolled steel elements have inherent fire resistance. This is a function of the size of the section, the degree of exposure to the fire and the load that the section carries. Unprotected steelwork deemed to have 15mins fire resistance. Fire protection is usually required. This is known as passive fire protection, which splits into:",
            <ul>
                <li>reactive: thin-film intumescent coatings (most common). Can be applied on-site or off-site. Typically used for buildings with fire resistance requirements of 30, 60 and 90 minutes. Some products can provide 120mins. They come in water or solvent-based depends on the intended use of the structure. It can cover complex shapes and post protection service installation is relatively simple.</li>
                <li>Non-reactive: boards and sprays
                    <ul>
                        <li>Boards are widely used for structural fire protection in the UK. There are lightweight 150-250kg/m3 which is generally cheaper and used where aesthetics are not important. Heavyweight 700-950kg/m3 boards accept decorative finishes.</li>
                        <li>Sprays protection is extensively used in the USA, can cover complex shape and details. Some can be used for external and hydrocarbon fire applications. The downside is not suitable for aesthetic purposes, and the application is a set trade which may impact on other site operations.</li>
                        <li>Flexible blanket systems are easily applied fire protection material that is a dry trade. There are limited manufacturers of these products.</li>
                    </ul>
                </li>
                <li>Concrete encasement: Traditional type of fire protection to steel. Tend to be used where resistance to impact damage, abrasion and weather exposure is important. Much higher cost, thickness and weight.</li>
            </ul>,
            "Detail guidance on the installation of coatings, boards and spray protection systems is available for the Association for Specialist Fire Protection. Info on the thickness of concrete encasement for specific periods of fire resistance is published by BRE and EC2.",
        ]
    },
];

export default articleContent;