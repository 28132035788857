import {Container} from 'react-bootstrap';
//Above Bootstrap import//

import React from 'react';
import './App.css';
import NavBar from './NavBar';
import HomePage from './Pages/HomePage';
import AboutPage from './Pages/AboutPage';
import ArticlePage from './Pages/ArticlePage';
import ArticleListPage from './Pages/ArticleListPage';
import NotFoundPage from './Pages/NotFoundPage';
import Calculator from './Pages/Calculator';
import Toolbox from './Pages/Toolbox';
import iBeamTorsion from './Calculators/IBeamTorsion';
import PortfolioPage from './Pages/PortfolioPage';
import {
  BrowserRouter as Router,
  Route,
Switch }
  from 'react-router-dom'

function App() {
  return (
    <div className="App">
      <Router>
          <NavBar />
          <Container>
              <Switch>
                <Route path="/" component={HomePage} exact />
                <Route path="/about/" component={AboutPage} />
                <Route path="/article-list" component={ArticleListPage} />
                <Route path="/calculator-list" component={Calculator} />
                <Route path="/toolbox" component={Toolbox} />
                <Route path="/article/:name" component={ArticlePage} />
                <Route path="/calculator/iBeamTorsion" component={iBeamTorsion} />
                <Route path="/portfolio" component={PortfolioPage} />
                <Route component={NotFoundPage} />

              </Switch>
            </Container>
        </Router>
    </div>
  );
}

export default App;
