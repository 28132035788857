import React,{useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Button, Alert, Row, ListGroup, ListGroupItem} from 'react-bootstrap';
import evaluate from '../Components/Evaluate';
import {Link} from 'react-router-dom';

function Calculator(){
    const [formula, setFormula] = useState('');
    const [result, setResult] = useState(0);

    return(
        <div>
            <div>
                <h1>Calculator</h1>
                <input className=' form-control-lg form-control-range' placeholder='Equation' value={formula} onChange={(event) => {setFormula(event.target.value)}} />
                <br />
                <Button variant='dark' className=' mx-3' size="lg" onClick={()=>{setResult(evaluate(formula))}}>Compute</Button>
                <Button variant='dark' size="lg" onClick={()=>{setFormula('')}}>Clear</Button>
                <br />
                <br />
                <Alert variant='info' className=' font-weight-bold'>The answer is <input value={result}/></Alert>
                <Row style={{display:'block', marginLeft:'auto', marginRight:'auto'}}>
                    <Button variant='dark' size="lg" className='m-2' onClick={()=>{setFormula(formula.concat('7'))}}>7</Button>
                    <Button variant='dark' size="lg" className='m-2' onClick={()=>{setFormula(formula.concat('8'))}}>8</Button>
                    <Button variant='dark' size="lg" className='m-2' onClick={()=>{setFormula(formula.concat('9'))}}>9</Button>
                    <Button variant='dark' size="lg" className='m-2' onClick={()=>{setFormula(formula.concat('+'))}}>+</Button>
                </Row>
                <Row style={{display:'block', marginLeft:'auto', marginRight:'auto'}}>
                    <Button variant='dark' size="lg" className='m-2' onClick={()=>{setFormula(formula.concat('4'))}}>4</Button>
                    <Button variant='dark' size="lg" className='m-2' onClick={()=>{setFormula(formula.concat('5'))}}>5</Button>
                    <Button variant='dark' size="lg" className='m-2' onClick={()=>{setFormula(formula.concat('6'))}}>6</Button>
                    <Button variant='dark' size="lg" className='m-2' onClick={()=>{setFormula(formula.concat('-'))}}>-</Button>
                </Row>
                <Row style={{display:'block', marginLeft:'auto', marginRight:'auto'}}>
                    <Button variant='dark' size="lg" className='m-2' onClick={()=>{setFormula(formula.concat('1'))}}>1</Button>
                    <Button variant='dark' size="lg" className='m-2' onClick={()=>{setFormula(formula.concat('2'))}}>2</Button>
                    <Button variant='dark' size="lg" className='m-2' onClick={()=>{setFormula(formula.concat('3'))}}>3</Button>
                    <Button variant='dark' size="lg" className='m-2' onClick={()=>{setFormula(formula.concat('*'))}}>x</Button>
                </Row>
                <Row style={{display:'block', marginLeft:'auto', marginRight:'auto'}}>
                    <Button variant='light' size="lg" className='m-2 text-white'>0</Button>
                    <Button variant='dark' size="lg" className='m-2' onClick={()=>{setFormula(formula.concat('0'))}}>0</Button>
                    <Button variant='light' size="lg" className='m-2 text-white'>0</Button>
                    <Button variant='dark' size="lg" className='m-2' onClick={()=>{setFormula(formula.concat('/'))}}>÷</Button>
                </Row>
            </div>
            <div>
                <h1>List of useful calculator</h1>
                <ListGroup>
                    <ListGroupItem>
                        <Link className="App-link" to="/calculator/iBeamTorsion">I beam Torsion calculator</Link>
                    </ListGroupItem>
                </ListGroup>
            </div>
        </div>
    );
}


export default Calculator;