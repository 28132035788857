import 'bootstrap/dist/css/bootstrap.min.css';

function evaluate(formula) {
    try {
        return eval(formula);
    }
    catch(err){
        alert("We could not solve the equation. Please try another");
    }
};

export default evaluate;