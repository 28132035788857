import React from 'react';
import articleContent from './articleContent';
import ArticlesList from '../Components/ArticlesList';

const ArticleListPage = () => (
    <div>
        <h1 classname="App-header">Articles</h1>
        <ArticlesList articles={articleContent} />
    </div>
);

export default ArticleListPage;