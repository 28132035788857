import 'bootstrap/dist/css/bootstrap.min.css'

import React ,{useState, useEffect} from 'react';
import articleContent from './articleContent';
import NotFoundPage from './NotFoundPage';

const ArticlePage = ({match}) => {

    const name = match.params.name;
    const article = articleContent.find(article => article.name===name);

    //const [articleInfo, setArticleInfo] = useState({upvotes:0, comments: [] });

    //Send request to Database//
    //useEffect(() => {
    //    const fetchData= async () => {
    //        const result = await fetch(`/api/articles/${name}`
    //        );
    //        const body = await result.json();
    //        console.log(body);
    //        setArticleInfo(body);
    //    }
    //    fetchData();
    //}, [name]);
    //End//


    if (!article) return <NotFoundPage />

    return (
        <div>
            <h1>{article.title}</h1>
            {/* {<UpvotesSection articleName={name} upvotes={articleInfo.upvotes} setArticleInfo={setArticleInfo}/> */}
            {article.content.map((paragragh, key) => (
                <p key={key} className="text-left">{paragragh}</p>
            ))}
            {/* <CommentsList comments={articleInfo.comments} /> */}
            {/* <AddCommentForm articleName={name} setArticleInfo={setArticleInfo} /> */}
            
        </div>
    );
};


export default ArticlePage;