import React from 'react';
import {Link} from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'

const NavBar = () => (
    <Container>
        <Navbar collapseOnSelect expand="lg" variant="dark" bg="dark">
            <Link className="navbar navbar-brand" to="/">MarvinSuen</Link>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    <Link className="nav-link navbar-dark" to="/article-list">Articles</Link>
                    <Link className="nav-link navbar-dark" to="/calculator-list">Calculator</Link>
                    <Link className="nav-link navbar-dark" to="/toolbox">Toolbox</Link>
                    <Link className="nav-link navbar-dark" to="/about">About</Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    </Container>
);

export default NavBar;